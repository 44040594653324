import styled from '@emotion/styled'
import { FlexColumn, Text, mobileMediaQuery, mobileSideSpace, spacing, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { UpdatePaymentForm } from '../organisms/payment-form/update-payment-form'

export const PaymentMethodUpdateForm = memo(function PaymentMethodUpdateForm(props: {
  stripeClientSecret: string
  token: string | undefined
  navigateBack?: () => void
  backButton: boolean
}): JSX.Element {
  const translate = useTranslate()
  const { stripeClientSecret, token, navigateBack, backButton } = props
  return (
    <FlexColumnWithAppropriateGapAndMargin>
      <Text variant="body2">{translate('payment_method_update_info')}</Text>
      <UpdatePaymentForm
        stripeClientSecret={stripeClientSecret}
        navigateBack={navigateBack}
        backButton={backButton}
        token={token}
      />
    </FlexColumnWithAppropriateGapAndMargin>
  )
})

const FlexColumnWithAppropriateGapAndMargin = styled(FlexColumn)`
  gap: ${spacing[70]};
  margin: 0;

  ${mobileMediaQuery} {
    gap: ${spacing[50]};
    margin: 0 ${mobileSideSpace};
  }
`
