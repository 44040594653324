import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Button,
  Dialog,
  FlexRow,
  IbanWizard,
  Spinner,
  TabBar,
  colors,
  mobileMediaQuery,
  spacing,
  useAsyncCallback,
  useTranslate,
  type IbanData,
} from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback, useState, type FormEvent } from 'react'
import { trpc } from '../../../client'
import { useStripePromise } from '../../../lib/payment-provider-util'
import { stripeElementsZIndex } from '../../../lib/z-indexes'
import { useStripeOptions } from './stripe-options'

type UpdatePaymentProps = {
  stripeClientSecret: string
  token: string | undefined
  navigateBack?: () => void
  backButton: boolean
}

export function UpdatePaymentForm({
  stripeClientSecret,
  navigateBack,
  backButton,
  token,
}: UpdatePaymentProps): JSX.Element {
  const stripePromise = useStripePromise()
  const stripeOptions = useStripeOptions(stripeClientSecret)

  return (
    <Elements options={stripeOptions} stripe={stripePromise}>
      <UpdatePaymentElementWrapperForm navigateBack={navigateBack} backButton={backButton} token={token} />
    </Elements>
  )
}

type UpdatePaymentElementWrapperFormProps = {
  navigateBack?: () => void
  backButton: boolean
  token: string | undefined
}

function UpdatePaymentElementWrapperForm({
  navigateBack,
  backButton,
  token,
}: UpdatePaymentElementWrapperFormProps): JSX.Element {
  const translate = useTranslate()
  const stripe = useStripe()
  const elements = useElements()
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string | null>(null)
  const [paymentMean, setPaymentMean] = useState<'sepa' | 'card'>('sepa')
  const [ibanData, setIbanData] = useState<IbanData | undefined>()

  const onPaymentMeanChange = useCallback((tabId: string) => {
    if (tabId !== 'sepa' && tabId !== 'card') return
    setPaymentMean(tabId)
  }, [])

  const handleSubmit = useAsyncCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      const returnUrl = document.location.toString()

      if (paymentMean === 'sepa') {
        if (!ibanData) return
        const result = await trpc.paymentMethod.receiveIban.mutate({ jwt: token, ...ibanData })
        if (isFailure(result)) {
          setStripeErrorMessage('Une erreur est survenue. Votre IBAN est-il correct ?')
          return
        }

        const successReturnUrl = new URL(returnUrl)
        successReturnUrl.searchParams.set('redirect_status', 'succeeded')

        window.location.href = successReturnUrl.href
        return
      }

      if (!stripe || !elements) {
        return
      }

      const confirmSetupPromise = stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          // eslint-disable-next-line camelcase
          return_url: returnUrl,
        },
      })
      const { error } = await confirmSetupPromise

      if (error) {
        setStripeErrorMessage(error.message || 'Erreur inconnue')
      }
    },
    [elements, stripe, ibanData, paymentMean, token],
  )

  return (
    <div
      css={css`
        padding-bottom: ${spacing[100]};
      `}
    >
      <form onSubmit={handleSubmit}>
        <TabBar
          size="large"
          fullWidth
          tabs={{
            sepa: translate('payment_method_sepa_debit'),
            card: translate('payment_method_card'),
          }}
          selectedTabId={paymentMean}
          onTabChange={onPaymentMeanChange}
          css={css`
            margin-bottom: ${spacing[70]};
          `}
        />
        {paymentMean === 'card' ? (
          <div
            css={css`
              min-height: 345px;
              position: relative;
            `}
          >
            <Spinner
              size="50"
              css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              `}
            />
            <div
              css={css`
                background-color: ${colors.white};
                z-index: ${stripeElementsZIndex};
                position: relative;
              `}
            >
              <PaymentElement />
            </div>
          </div>
        ) : (
          <div>
            <IbanWizard
              initialFirstName=""
              initialLastName=""
              initialEmail=""
              initialAddress=""
              initialPostalCode=""
              initialCity=""
              onValidate={setIbanData}
            />
          </div>
        )}
        <ButtonRow>
          <Button onClick={navigateBack} variant="secondary" disabled={!backButton}>
            {translate('cancel')}
          </Button>
          {paymentMean === 'card' || !!ibanData ? (
            <Button type="submit" variant="primary">
              {translate('save')}
            </Button>
          ) : null}
        </ButtonRow>
      </form>

      {stripeErrorMessage ? (
        <Dialog
          size="small"
          title={translate('payment_method_update_error')}
          onClose={() => setStripeErrorMessage(null)}
        >
          {stripeErrorMessage}
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  )
}

const ButtonRow = styled(FlexRow)`
  gap: ${spacing[50]};
  margin: ${spacing[70]} 0 0 0;
  justify-content: flex-end;

  ${mobileMediaQuery} {
    flex-direction: column-reverse;
    justify-content: stretch;
  }
`
